const href = (link) => {
  if (link.url === '@custom' || link.url === '@media') {
    return link.otherUrl;
  }

  return link.url || '#';
}

const media_link = (link) => !!link.src;

const link_classes = (link) => {
  if (!!link.tag) {
    return link.tag;
  }
}

const megamenu_wrapper = (link) => link.url === '@wrapper';

const megamenu_link_dropdown = (link) => {
  return typeof link.sublinks != 'undefined';
}

const megamenu_link_flyout = (link) => {
  if (!!link.sublinks) {
    return link.sublinks.some(element => typeof element.sublinks != 'undefined');
  }
}

const handleFlyoutMoreClick = (event, props) => {
  event.preventDefault();

  props.active ? props.onMouseLeave() : props.onMouseEnter();
}

const handleTopLevelKeyPress = (event, props) => {
  if (event.key === 'Enter' || event.key === ' ') {
    event.preventDefault();

    props.onMouseEnter();
  }

  if (event.key === 'Escape') {
    event.preventDefault();

    props.onMouseLeave();
  }
};

const handleFlyoutKeypress = (event, props) => {
  if (event.key === 'Escape') {
    event.preventDefault();

    props.onMouseLeave();
  }
}

export { href, media_link,
         link_classes, megamenu_wrapper,
         megamenu_link_dropdown,
         megamenu_link_flyout,
         handleFlyoutKeypress,
         handleTopLevelKeyPress,
         handleFlyoutMoreClick
       };
